<template>
    <div class="examsAnalyseMissingDialog">
        <el-dialog :title="myTitle" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">

            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="学号、姓名"  @keyup.enter.native='loadStudentList'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" @click='search'>查询</el-button>
                </el-form-item>

            </el-form>
            <div class="table_container">
                <el-table v-loading="loading" :data="tableData" style="width: 100%;" max-height="500" align='center'>
                    <el-table-column label="序号" :formatter="formatterIndex" align='center'></el-table-column>
                    <el-table-column prop="student_no" label="学号"  min-width="150" align='center' ></el-table-column>
                    <el-table-column prop="student_name" label="姓名"  min-width="150" align='center' ></el-table-column>
                    <el-table-column prop="hard_name" label="行政班" align='center' ></el-table-column>

                </el-table>
                <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
            </div>
            
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关  闭</el-button>

            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'
    import Pagination from "@/components/pagination";

    export default {
        name: 'examsAnalyseMissingDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '缺考学生',
                tableData: [], tableHeight:0,
                isShow:false,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, examId: null, },
                dialogVisible:false, loading: false, examId: 0,


            }
        },
        components:{
            Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {

        },
        watch: {

        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            formatterIndex(row, column, cellValue, index) {

                let base = 0;
                if((this.searchForm.pageNo-1) > 0){
                    base = (this.searchForm.pageNo-1) * this.searchForm.pageSize;
                }
                return base + index+1;
            },
            search(){
                this.loadStudentList(this.searchForm.examId);
            },
            loadStudentList(examId){
                this.loading = true;
                this.searchForm.examId = examId;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    examId: examId};
                let param = { controllerName: 'exam', methodName: '/missingExamList', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        let list = data.result;
                        this.tableData = list;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            submitForm(){
                this.$emit('ok');
            },
            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadStudentList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadStudentList()
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .examsAnalyseMissingDialog{
        .my-row{
            padding: 10px;
        }

        .head-warning {
            margin-top: 20px;
            color: red;
        }

    }


</style>


