<template>
    <div class="examsAnalyse">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                <el-form-item label="试卷列表(已结束)">
                    <el-select v-model="searchForm.examId" filterable placeholder="请选择" @change="examChange" style="min-width: 450px;">
                        <el-option v-for="item in examList" :key="item.exam_id" :label="item.exam_name+'('+ item.exam_id+')'" :value="item.exam_id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" size ="mini" icon="search" @click='loadTotal'>查询</el-button>
                </el-form-item>

            </el-form>
        </div>
        <el-tabs type="border-card" v-if="searchForm.examId != null">
            <el-tab-pane label="考情总览" v-loading="loading">
                <el-row class="row-head">
                    <div class="splite"></div>
                    <div style="float: left;margin-left: 20px;">
                        <span >概览</span>
                    </div>

                </el-row>
                <el-row :gutter="10" class="row_list">
                    <el-col :span="4" class="row_left row_base" :offset="2">
                        <div class="pay saleBgcolor data_list rflex">
                            <div class="leftItem cflex wflex">
                                <p class="investor">实考人数/总人数</p>
                                <p class="number">{{examCount.joinCount}}/{{examCount.totalCount}}</p>
                            </div>
                            <div class="rightItem">
                                <i class="el-icon-user"></i>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4" class="row_left row_base">
                        <div class="pay taxBgcolor data_list rflex">
                            <div class="leftItem cflex wflex">
                                <p class="investor">最高分</p>
                                <p class="number offline">{{examCount.maxScore}}</p>
                            </div>
                            <div class="rightItem">
                                <i class="el-icon-top" />
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4" class="row_left row_base">
                        <div class="pay extenedBgcolor data_list rflex">
                            <div class="leftItem cflex wflex">
                                <p class="investor">平均分</p>
                                <p class="number">{{examCount.avaScore}}</p>
                                <!--<p class="cardText">Decreased by 10%</p>-->
                            </div>
                            <div class="rightItem">
                                <i class="el-icon-s-data" />
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4" class="row_left row_base">
                        <div class="pay keleBgColor data_list rflex">
                            <div class="leftItem cflex wflex">
                                <p class="investor">优秀率</p>
                                <p class="number">{{examCount.goodScale}}<span class="perTitle">%</span></p>
                                <!--<p class="cardText">Decreased by 50%</p>-->
                            </div>
                            <div class="rightItem">
                                <i class="el-icon-goblet-square" />
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4" class="row_left row_base">
                        <div class="pay linkBgColor data_list rflex">
                            <div class="leftItem cflex wflex">
                                <p class="investor">及格率</p>
                                <p class="number">{{examCount.upScale}}<span class="perTitle">%</span></p>
                                <!--<p class="cardText">Decreased by 10%</p>-->
                            </div>
                            <div class="rightItem">
                                <i class="el-icon-lollipop" />
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="overview-desc" style="margin-top: 20px;">
                    <el-col :span="24">
                        <span>参与情况：</span>
                        <span>共{{examCount.hardClassCount}}个班级，{{examCount.joinCount}}人参加考试，{{examCount.totalCount-examCount.joinCount}}人未参加考试。查看</span>
                        <el-link type="primary" @click="showExamsAnalyseMissingDialog">未参加考试学生名单</el-link>
                    </el-col>
                </el-row>
                <el-row class="overview-desc">
                    <el-col :span="24">
                        <span>优秀率：</span>
                        <span>考试成绩达到总分的90%及以上的学生占比。</span>
                    </el-col>
                </el-row>
                <el-row class="overview-desc">
                    <el-col :span="24">
                        <span>及格率：</span>
                        <span>考试成绩达到总分的60%及以上的学生占比。</span>
                    </el-col>
                </el-row>
                <div style="height: 20px; width: 100%"></div>
                <el-row class="row-head">
                    <div class="splite"></div>
                    <div style="float: left;margin-left: 20px;">
                        <span >成绩分布</span>
                    </div>
                </el-row>
                <el-row>
                    <el-col :span="20" :offset="1">
                        <div id="scoreBar" style="width: 100%;height:300px;"></div>
                    </el-col>
                </el-row>
                <div style="height: 20px; width: 100%"></div>

                <el-row class="row-head">
                    <div class="splite"></div>
                    <div style="float: left;margin-left: 20px;">
                        <span >成绩分档统计</span>
                    </div>
                </el-row>
                <el-row>
                    <el-col :span="20" :offset="1">
                        <div id="scoreLevelPie" style="width: 100%;height:300px;"></div>
                    </el-col>
                </el-row>
                <div style="height: 20px; width: 100%"></div>
                <el-row class="row-head">
                    <div class="splite"></div>
                    <div style="float: left;margin-left: 20px;">
                        <span >各班考试平均分</span>
                    </div>
                </el-row>
                <el-row>
                    <el-col :span="20" :offset="1">
                        <div id="classBar" :style="classBarStyle"></div>
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="试卷分析">
                <el-row class="row-head">
                    <div class="splite"></div>
                    <div style="float: left;margin-left: 20px;">
                        <span >考试情况概述</span>
                    </div>

                </el-row>
                <el-row :gutter="10" class="row_list">
                    <el-col :span="4" class="row_left row_base" >
                        <div class="pay saleBgcolor data_list rflex">
                            <div class="leftItem cflex wflex">
                                <p class="number">{{examInfo.totalScore}}</p>
                                <p class="investor">试卷总分</p>
                            </div>
                            <div class="rightItem">
                                <i class="el-icon-user"></i>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4" class="row_left row_base">
                        <div class="pay taxBgcolor data_list rflex">
                            <div class="leftItem cflex wflex">
                                <p class="number offline">{{examCount.maxScore}}</p>
                                <p class="investor">最高分</p>
                            </div>
                            <div class="rightItem">
                                <i class="el-icon-top" />
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4" class="row_left row_base">
                        <div class="pay extenedBgcolor data_list rflex">
                            <div class="leftItem cflex wflex">
                                <p class="number">{{examInfo.hard}}</p>
                                <p class="investor">难度</p>
                                <!--<p class="cardText">Decreased by 10%</p>-->
                            </div>
                            <div class="rightItem">
                                <i class="el-icon-s-data" />
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4" class="row_left row_base">
                        <div class="pay keleBgColor data_list rflex">
                            <div class="leftItem cflex wflex">
                                <p class="number">{{examInfo.diff}}</p>
                                <p class="investor">区分度</p>
                                <!--<p class="cardText">Decreased by 50%</p>-->
                            </div>
                            <div class="rightItem">
                                <i class="el-icon-goblet-square" />
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4" class="row_left row_base">
                      <div class="pay linkBgColor data_list rflex">
                        <div class="leftItem cflex wflex">
                          <p class="number">{{examInfo.xindu}}</p>
                          <p class="investor">信度</p>
                          <!--<p class="cardText">Decreased by 50%</p>-->
                        </div>
                        <div class="rightItem">
                          <i class="el-icon-lollipop" />
                        </div>
                      </div>
                    </el-col>
                  <el-col :span="4" class="row_left row_base">
                    <div class="pay likeBgcolor data_list rflex">
                      <div class="leftItem cflex wflex">
                        <el-popover placement="top-start"
                                    title="bartlett科学技术法" width="200" trigger="hover"
                                    :content="examInfo.bartValue">
                          <p class="number" slot="reference">{{examInfo.kmo}}/{{examInfo.bartlett}}</p>
                        </el-popover>
                        <p class="investor">效度(kmo/bartlett)</p>
                        <!--<p class="cardText">Decreased by 50%</p>-->
                      </div>
                      <div class="rightItem">
                        <i class="el-icon-lollipop" />
                      </div>
                    </div>
                  </el-col>

                </el-row>
                <el-row class="overview-desc" >
                    <el-col :span="24">
                        <span>区分度：</span>
                        <span>试卷对考生水平高度的区分难度，值越大越好。一般0.4以上表明试题区分度很好；0.19以下表明试题区分度差</span>
                    </el-col>
                </el-row>
                <el-row class="overview-desc">
                    <el-col :span="24">
                        <span>计算公式：</span>
                        <span>D=PH - PL. PH为高分组（即得分最高的27%）被试在该题上的通过率。PL为低分组（即得分最低的27%）被试在该题上的通过率。D为区分度指数，即：先将分数排序，区分度=（27%高分组的平均分-27%低分组的平均分） / 满分值</span>
                    </el-col>
                </el-row>
                <el-row class="overview-desc">
                    <el-col :span="24">
                        <span>难度：</span>
                        <span> L 为难度系数， X 为样本平均得分， W 为试卷总分</span>
                    </el-col>
                </el-row>
                <el-row class="overview-desc">
                    <el-col :span="24">
                        <span>计算公式：</span>
                        <span>L=1—X/W</span>
                    </el-col>
                </el-row>
              <el-row class="overview-desc">
                <el-col :span="24">
                  <span>信度：</span>
                  <span>采用克隆巴赫Alpha值计算。a<0.6,说明信度低；0.7>=a>0.8,信度较好；0.9>a>=0.8，信度非常好；0.9>=a>1，信度最好，达到最好的标准化考试水平。</span>
                </el-col>
              </el-row>
              <el-row class="overview-desc"  style="margin-bottom: 20px;">
                <el-col :span="24">
                  <span>效度：</span>
                  <span>采用探索性因子分析法，通过计算KMO值和Bartlettp值，得出是否满足结构效度。<br/>
KMO值为0.9以上表示非常适合，0.8表示适合，0.7表示一般，0.6表示不太适合，0.5以下表示极不适合。KMO值取值在0和1之间。<br/>
Bartlettp值<0.05,说明符合标准，各变量在一定程度上相互独立。</span>
                </el-col>
              </el-row>
                <el-row class="row-head">
                    <div class="splite"></div>
                    <div style="float: left;margin-left: 20px;">
                        <span >小题得分情况</span>
                    </div>
                    <div style="float: right;">
                        <jsonExcel class="export-excel-wrapper"  :data="tiData" :fields="excelFields" :beforeGenerate="excelOutput" name="考试分析.xls">
                            <el-link type="primary" style="z-index: 99">导出Excel</el-link>
                        </jsonExcel>

                    </div>
                </el-row>
                <div class="table_container">
                    <el-table  :data="tiData" style="width: 100%" align='center'>

                        <el-table-column prop="ti" label="题号"  :formatter="formatterSeq" align='center' ></el-table-column>
                        <el-table-column prop="type" label="题型"  :formatter="formatterType" width="150" align='center' ></el-table-column>
                        <el-table-column prop="score0Over" label="得分率"  align='center' ></el-table-column>
                        <el-table-column prop="hard_mode" label="难度" align='center' ></el-table-column>
                        <el-table-column prop="discrimination" label="区分度" align='center' ></el-table-column>
                        <el-table-column prop="avg" label="均分" align='center' ></el-table-column>
                        <el-table-column prop="fs" label="满分" align='center' ></el-table-column>
                      <el-table-column prop="anw_count" label="选项计数（客观题）"  min-width="150" align='center' >
                        <template slot-scope='{row}'>
                          <div v-for="(itemA, indexA) in row.anw_count" v-if="['SingleChoice', 'MultiChoice', 'Judge'].includes(row.type)">
                            <el-button type="text" style="padding: 2px;" @click="answerStudentFilter(row.ti, itemA.anw)">{{itemA.anw+':'+itemA.count}}</el-button>
                          </div>
                        </template>
                      </el-table-column>

                    </el-table>
                </div>
            </el-tab-pane>
        </el-tabs>

        <examsAnalyseScoreLevelDialog ref="examsAnalyseScoreLevelDialog"  @ok="setScoreLevel" ></examsAnalyseScoreLevelDialog>
        <examsAnalyseMissingDialog ref="examsAnalyseMissingDialog"></examsAnalyseMissingDialog>
      <el-dialog :title="answerTitle" width="600"  :visible.sync="answerVisible" :close-on-click-modal="false">

        <el-table
            :data="stuAnwData.filter(data => !stuSearch || (data.hardName.toLowerCase().includes(stuSearch.toLowerCase()) || data.studentNo.toLowerCase().includes(stuSearch.toLowerCase()) || data.studentName.toLowerCase().includes(stuSearch.toLowerCase())))"
                  style="width: 100%;" height="400" align='center'>
          <el-table-column label="序号" type="index" align='center'></el-table-column>
          <el-table-column prop="hardName" label="行政班" align='center' ></el-table-column>
          <el-table-column prop="studentNo" label="学号"  align='center' ></el-table-column>
          <el-table-column prop="studentName" label="姓名"  align='center' ></el-table-column>
          <el-table-column prop="us" label="总分" width="200" align='center' >
            <template slot="header" slot-scope="scope">
              <el-input v-model="stuSearch" size="mini" style="width: 120px" placeholder="输入关键字搜索"/>
            </template>
          </el-table-column>
        </el-table>

        <div slot="footer" class="dialog-footer">
          <el-button @click="()=>answerVisible=false">关  闭</el-button>

        </div>
      </el-dialog>
    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import { use } from "echarts/core";
    import axios from 'axios';
    import * as echarts from 'echarts';
    import examsAnalyseScoreLevelDialog from "./examsAnalyseScoreLevelDialog";
    import examsAnalyseMissingDialog from "./examsAnalyseMissingDialog";
    import * as simpleApi from "request/simple";
    import XEUtils from "xe-utils";

    export default {
        name: 'examsAnalyse',

        data(){
            return {
                tableData: [], tableHeight:0, tiData: [], jsonData: null, excelData:[],
                loading: false, isShow:false,
                examCount: { joinCount: 997, totalCount: 1033, hardClassCount: 0, maxScore: 100, avaScore: 72.86, goodScale: 25.0, upScale: 77.01},
                examInfo: {totalScore: 100, avaScore: 0, hard: '暂无', diff: '暂无', xindu: '暂无', kmo: '暂无', bartlett: '暂无', bartValue: '暂无'},
                examList: [], classBarStyle: {width: '100%', height: '300px'}, studentData: null, stuAnwData: [], stuSearch: null, answerTitle: null, answerVisible: false,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, examId: null},
                scoreLevels: {score90: {from: 90, to: 100}, score80: {from: 80, to: 90}, score70: {from: 70, to: 80},
                    score60: {from: 60, to: 70}, score0: {from: 0, to: 60},},
                scoreBarOptions: {
                    tooltip: {},
                    xAxis: {
                        type: 'category',
                        data: ['0', '4', '8', '12', '16', '20', '24', '20', '20', '20', '20', '20', '20', '20']
                    },
                    yAxis: {type: 'value'},
                    visualMap: {
                        orient: 'horizontal',
                        left: 'center',
                        text: ['高', '低'],
                        min: 0,
                        max: 100,
                        dimension: 0,
                        inRange: {
                            color: ['#E15457', '#3087FF']
                        }
                    },
                    series: [{
                        name: '分数',
                        type: 'bar',
                        encode: { x: 'score', y: 'count'},
                        data: []
                    }]
                },
                scoreLevelPieOption: {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {c} ({d}%)'
                    },
                    legend: {
                        top: 10,
                        orient: 'vertical',
                        left: 'left',
                        data: ['优秀', '良好', '中等', '及格', '不及格']
                    },
                    toolbox: {
                        // itemSize: 20,
                        feature: {
                            myTool1: {
                                show: true, title: '分数设置',
                                icon: 'path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891',
                                onclick: this.showScoreLevelDialog
                            }
                        }
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '65%',
                            center: ['50%', '50%'],
                            selectedMode: 'single',
                            label: {
                                fontSize: 18,
                                color: '#235894',
                                formatter: '{b} : {c} ({d}%)'
                            },
                            data: [
                                {
                                    value: 247,
                                    name: '优秀',
                                },
                                {value: 169, name: '良好'},
                                {value: 174, name: '中等'},
                                {value: 178, name: '及格'},
                                {value: 229, name: '不及格'}
                            ],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                },
                classBarOptions: {

                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    visualMap: {
                        orient: 'horizontal',
                        left: 'center',
                        min: 10,
                        max: 100,
                        text: ['高分', '低分'],
                        // Map the score column to color
                        dimension: 0,
                        inRange: {
                            color: ['#E15457', '#3087FF']
                        }
                    },
                    xAxis: {
                        type: 'value', nameLocation : 'center', nameGap: 16,
                    },
                    yAxis: {
                        type: 'category',
                        data: []
                    },
                    series: [
                        {
                            type: 'bar', label: {show: true, position: 'right', color: '#000000'},
                            encode: {
                                x: 'scores',
                                y: 'names'
                            },
                            data: []
                        },

                    ]
                },
                excelFields: {
                    "题号": {
                        field: "ti",
                        callback: value =>{
                            return value+1;
                        }
                    }, "题型": {
                        field: "type",
                            callback: cellValue =>{
                                if(cellValue == "SingleChoice")return "单选题";
                                if(cellValue == "MultiChoice")return "多选题";
                                if(cellValue == "Judge")return "判断题";
                                if(cellValue == "AnswerQuestion")return "解答题";
                                if(cellValue == "FillBlank")return "填空题";
                                if(cellValue == "Composition")return "作文题";
                                if(cellValue == "EnglishComposition")return "英语作文题";
                                return cellValue;
                        }
                    },"得分率": 'score0Over',"难度": "hard_mode", "区分度": "discrimination", "均分": "avg", "满分: ": "fs",
                    "选项计数（客观题）": {
                        field: "anw_count",
                        callback: cellValue =>{
                            if(cellValue && cellValue.length > 0){
                                let s = "";
                                for (let i=0; i<cellValue.length; i++){
                                    let d = cellValue[i];
                                    if(d.anw != 0 || d.anw != ""){
                                        s = s + ""+d.anw + ":" + d.count + ","
                                    }
                                }
                                return s
                            }
                            return "";
                        }
                    },

                }
            }
        },
        components:{
            examsAnalyseScoreLevelDialog, examsAnalyseMissingDialog
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
        created() {
            let scoreXList = [];
            let yList = [];
            for (let i=0; i<26; i++){
                scoreXList.push('' + (i*4));
                if(i < 5){
                    yList.push(i);
                }else{
                    let j = Math.round(Math.random()*4*i) + 10;
                    yList.push(j);
                }
            }
            console.log(scoreXList, yList);
            this.scoreBarOptions.xAxis.data = scoreXList;
            this.scoreBarOptions.series[0].data = yList;
        },
        mounted() {
            this.loadExamList();
            // this.loadTiData();
            // let that = this;
            // this.$nextTick(() => {
            //     let scoreBar = echarts.init(document.getElementById("scoreBar"));
            //     scoreBar.setOption(that.scoreBarOptions);
            //     let scoreLevelPie = echarts.init(document.getElementById("scoreLevelPie"));
            //     scoreLevelPie.setOption(that.scoreLevelPieOption);
            //     let classBar = echarts.init(document.getElementById("classBar"));
            //     classBar.setOption(that.classBarOptions);
            //
            // })

	   },
        methods: {
            examChange(){
              let that = this;
                this.examInfo = {totalScore: 100, avaScore: 0, hard: '暂无', diff: '暂无', xindu: '暂无', kmo: '暂无', bartlett: '暂无', bartValue: '暂无'};
                let v = this.searchForm.examId;
                let examFind = XEUtils.find(this.examList, item => item.exam_id == v);
                console.log("examChange", examFind)
                if(examFind){
                    let analyse_oss_name = examFind.analyse_oss_name
                    if(analyse_oss_name != null){
                        analyse_oss_name = window.g.OSS_HOST + analyse_oss_name+ "?_=" + (new Date()).getTime();
                      this.loadExamCtrl(analyse_oss_name, function (){
                        that.loadStudents(v, function (){
                          that.loading = false;
                        })
                      });
                    }
                }
                // this.loadTotal();
            },
            loadStudents(examId, callback){
              let analyse_oss_name = window.g.OSS_HOST+"answer/analyse/"+examId+"/exam_student_"+examId+".json";
              axios.defaults.headers = {'Content-Type': 'application/json;charset=UTF-8'}
              axios({
                method:'get',
                url:analyse_oss_name + "?_=" + (new Date()).getTime(),
              }).then((res)=>{
                let data = res.data;
                this.studentData = data;

                if(callback) callback();
              }).catch((error) => {
                console.log("error", error)
              });
            },
            answerStudentFilter(ix, answer){
              if(this.studentData){
                console.log("f", ix, answer)
                let hardStudents = XEUtils.filter(this.studentData, item => {
                  return item.ti_list[ix].anw == answer
                });

                this.stuAnwData = []
                let anwList = []
                if(hardStudents && hardStudents.length > 0){
                  for (let j=0; j<hardStudents.length; j++){
                    let d = hardStudents[j];
                    anwList.push({studentId: d.student_id, studentNo: d.student_no, studentName: d.student_name, hardName: d.hard_name, us: d.us })
                  }
                }
                this.stuAnwData = anwList
              }
              this.answerTitle = "第"+(ix+1)+"题，选择："+answer
              this.answerVisible = true;
            },
            loadExamList(){

                let search = { licenceId: this.userInfo.licenceId, teacherId: this.userInfo.id, status: 'ZC', orderBy: 'e.id desc' };
                let param = { controllerName: 'exam', methodName: '/downloadExamList', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.examList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },

            loadExamCtrl(analyse_oss_name, callback){
                this.loading = true;
                let that = this;
                axios.defaults.headers = {'Content-Type': 'application/json;charset=UTF-8'}
                axios({
                    method:'get',
                    url:analyse_oss_name,
                }).then((res)=>{
                    let data = res.data;
                    console.log(data);
                    that.examCount.joinCount = data.join_count;
                    that.examCount.totalCount = data.student_count;
                    that.examCount.hardClassCount = data.hard_count;
                    that.examInfo.totalScore = data.full_score;
                    that.examCount.maxScore = data.score_max;
                    that.examCount.avaScore = data.score_avg;
                    that.examInfo.hard = data.hard_mode;
                    that.examInfo.diff = data.discrimination;
                    if("xindu" in data) that.examInfo.xindu = Math.round(data.xindu * 10000)/10000;
                  if("kmo" in data) that.examInfo.kmo = Math.round(data.kmo * 10000)/10000;
                  if("bartlett" in data) {
                    console.log("bartlett", data.bartlett, parseFloat(data.bartlett))
                    that.examInfo.bartlett = data.bartlett;
                    that.examInfo.bartValue = data.bartValue;
                  }
                    that.setScoreBarData(data.score_count);
                    that.loadScoreRule();
                    that.setScoreLevelData(data.score_level);
                    that.setClassBarData(data.hard_avg_count);
                    let scores = data.scores;
                    scores.forEach((item, index) => {
                        item["score0Over"] = Math.round(item.over0 / (item.fs*data.join_count) * 10000)/100 + "%";
                    });
                    that.tiData = scores;
                    that.jsonData = data;
                    if(callback) callback()
                    else that.loading = false;
                }).catch((error) => {
                    that.loading = false;
                });

            },
            loadTotal(){
                let that = this;
                let v = parseInt(this.searchForm.examId);
                let examFind = XEUtils.find(this.examList, item => item.exam_id == v);
                // console.log("examChange", examFind, v, this.examList)
                if(examFind){
                    let analyse_oss_name = examFind.analyse_oss_name
                    if(analyse_oss_name != null){
                        analyse_oss_name = window.g.OSS_HOST + analyse_oss_name;
                        this.loadExamCtrl(analyse_oss_name);
                    }
                }

            },
            setScoreBarData(data){

                let scoreXList = data.score;
                let scoreYList = data.count;
                this.scoreBarOptions.xAxis.data = scoreXList;
                this.scoreBarOptions.series[0].data = scoreYList;
                let scoreBar = echarts.init(document.getElementById("scoreBar"));
                scoreBar.setOption(this.scoreBarOptions);

            },
            setScoreLevel(){
                let scoreForm = this.scoreLevels;
                console.log("setScoreLevel", scoreForm)
                let scoreCount = this.jsonData.score_count
                let totalScore = this.examInfo.totalScore
                scoreForm.score0.from = 0
                scoreForm.score0.to = Math.ceil(totalScore*0.6)
                scoreForm.score60.from = Math.ceil(totalScore*0.6)
                scoreForm.score60.to = Math.ceil(totalScore*0.7)
                scoreForm.score70.from = Math.ceil(totalScore*0.7)
                scoreForm.score70.to = Math.ceil(totalScore*0.8)
                scoreForm.score80.from = Math.ceil(totalScore*0.8)
                scoreForm.score80.to = Math.ceil(totalScore*0.9)
                scoreForm.score90.from = Math.ceil(totalScore*0.9)
                scoreForm.score90.to = totalScore+1
                let list = scoreCount.score;
                let good90 = {name: "优秀(" + scoreForm.score90.from + "-" + scoreForm.score90.to + ")", count: 0};
                let good80 = {name: "良好(" + scoreForm.score80.from + "-" + scoreForm.score80.to + ")", count: 0};
                let good70 = {name: "中等(" + scoreForm.score70.from + "-" + scoreForm.score70.to + ")", count: 0};
                let good60 = {name: "及格(" + scoreForm.score60.from + "-" + scoreForm.score60.to + ")", count: 0};
                let good0 = {name: "不及格(" + scoreForm.score0.from + "-" + scoreForm.score0.to + ")", count: 0};
                let good_count = 0, up_count = 0, total_count = 0;

                for (let i=0; i< list.length; i++){
                    let d = list[i];
                    if(d >=scoreForm.score0.from && d < scoreForm.score0.to){
                        good0.count = good0.count + scoreCount.count[i];
                        good0.name = "不及格(" + scoreForm.score0.from + "-" + scoreForm.score0.to + ")";
                        total_count = total_count + scoreCount.count[i];
                    }
                    if(d >=scoreForm.score0.to && d < scoreForm.score60.to){
                        good60.count = good60.count + scoreCount.count[i];
                        good60.name = "及格(" + scoreForm.score60.from + "-" + scoreForm.score60.to + ")";
                        up_count = up_count + scoreCount.count[i];
                        total_count = total_count + scoreCount.count[i];
                    }
                    if(d >=scoreForm.score60.to && d < scoreForm.score70.to){
                        good70.count = good70.count + scoreCount.count[i];
                        good70.name = "中等(" + scoreForm.score70.from + "-" + scoreForm.score70.to + ")";
                        up_count = up_count + scoreCount.count[i];
                        total_count = total_count + scoreCount.count[i];
                    }
                    if(d >=scoreForm.score70.to && d < scoreForm.score80.to) {
                        good80.count = good80.count + scoreCount.count[i];
                        good80.name = "良好(" + scoreForm.score80.from + "-" + scoreForm.score80.to + ")";
                        up_count = up_count + scoreCount.count[i];
                        total_count = total_count + scoreCount.count[i];
                    }
                    if(d >=scoreForm.score80.to ) {
                        good90.count = good90.count + scoreCount.count[i];
                        good90.name = "优秀(" + scoreForm.score90.from + "-" + scoreForm.score90.to + ")";
                        up_count = up_count + scoreCount.count[i];
                        good_count = good_count + scoreCount.count[i];
                        total_count = total_count + scoreCount.count[i];
                    }
                }
                this.examCount.goodScale = Math.round(good_count/total_count *10000 )/100 ;
                this.examCount.upScale = Math.round(up_count/total_count*10000)/100;
                let levelList = [good90, good80, good70, good60, good0];
                console.log("levelList", levelList)
                this.setScoreLevelData(levelList);
            },
            setScoreLevelData(data){

                console.log("setScoreLevelData", data)
                let list = [];
                let legendData = [];
                for (let i=0; i<data.length; i++){
                    list.push({value: data[i].count, name: data[i].name})
                    legendData.push(data[i].name);
                }

                this.scoreLevelPieOption.legend.data = legendData;
                this.scoreLevelPieOption.series[0].data = list;
                let scoreBar = echarts.init(document.getElementById("scoreLevelPie"));
                scoreBar.setOption(this.scoreLevelPieOption);
            },
            setClassBarData(data){
                let hardNameList = data.names;
                if(hardNameList != null && hardNameList.length > 0){
                    let valueList = data.scores;
                    this.classBarStyle.height = hardNameList.length * 18 + 150 + "px";
                    this.$set(this, "classBarStyle", this.classBarStyle);
                    this.classBarOptions.yAxis.data = hardNameList;
                    this.classBarOptions.visualMap.max = this.examCount.maxScore;
                    this.classBarOptions.series[0].data = valueList;
                    let that = this;
                    setTimeout(function () {
                        let classBar = echarts.init(document.getElementById("classBar"));
                        // if(classBar != null) classBar.resize();
                        console.log("classBarStyle.height", classBar, that.classBarStyle.height);
                        classBar.setOption(that.classBarOptions);
                        classBar.resize({height: that.classBarStyle.height});
                    }, 800);
                }
                // console.log(hardNameList, valueList)
            },
            loadScoreRule(){
                let examId = this.searchForm.examId;
                let that = this;
                console.log("loadExamPages")
                let search = { licenceId: this.userInfo.licenceId, examId: examId};
                let param = { controllerName: 'score/ctrl', methodName: '/one',  param: search};
                return simpleApi.post(param).then(({result: {code, data}}) =>  {
                    console.log("loadScoreRule", data)
                    if (data) {
                        if(data.entity && data.entity.scoreRule){
                            let scoreRule = data.entity.scoreRule;
                            try{
                                console.log("scoreRule", scoreRule)
                                let jo = JSON.parse(scoreRule);
                                console.log("pase", jo)
                                if("scoreRule" in jo){
                                    let sr = jo["scoreRule"];
                                    this.scoreLevels.score90.from = sr["score90"]["from"];
                                    this.scoreLevels.score90.to = sr["score90"]["to"];
                                    this.scoreLevels.score80.from = sr["score80"]["from"];
                                    this.scoreLevels.score80.to = sr["score80"]["to"];
                                    this.scoreLevels.score70.from = sr["score70"]["from"];
                                    this.scoreLevels.score70.to = sr["score70"]["to"];
                                    this.scoreLevels.score60.from = sr["score60"]["from"];
                                    this.scoreLevels.score60.to = sr["score60"]["to"];
                                    this.scoreLevels.score0.from = sr["score0"]["from"];
                                    this.scoreLevels.score0.to = sr["score0"]["to"];
                                }
                            }catch (e) {
                                console.log("loadScoreRule", e)
                            }
                        }
                    }
                    that.setScoreLevel();
                }).catch((error) => {
                    console.log("error")
                });
            },
            excelOutput(){
            },
            showScoreLevelDialog(){
                console.log("showScoreLevelDialog", this.searchForm.examId)
                this.$refs.examsAnalyseScoreLevelDialog.loadScoreRule(this.searchForm.examId);
                this.$refs.examsAnalyseScoreLevelDialog.showDialog();
            },
            showExamsAnalyseMissingDialog(){
                console.log("showScoreLevelDialog", this.searchForm.examId)
                this.$refs.examsAnalyseMissingDialog.loadStudentList(this.searchForm.examId);
                this.$refs.examsAnalyseMissingDialog.showDialog();

            },
            formatterSeq(row, column, cellValue, index){
                return cellValue+1;
            },
            formatterType(row, column, cellValue, index){
                if(cellValue == "SingleChoice")return "单选题";
                if(cellValue == "MultiChoice")return "多选题";
                if(cellValue == "Judge")return "判断题";
                if(cellValue == "AnswerQuestion")return "解答题";
                if(cellValue == "FillBlank")return "填空题";
                if(cellValue == "Composition")return "作文题";
                if(cellValue == "EnglishComposition")return "英语作文题";

                return cellValue;
            },
            formatterOver0(row, column, cellValue, index){
                return Math.round(cellValue / (row.fs*this.examCount.joinCount) * 10000)/100 + "%";
            },
            formatterAnwCount(row, column, cellValue, index){
                if(cellValue && cellValue.length > 0){
                    let s = "";
                    for (let i=0; i<cellValue.length; i++){
                        let d = cellValue[i];
                        if(d.anw != 0 || d.anw != ""){
                            s = s + ""+d.anw + ":" + d.count + ","
                        }
                    }
                    return s
                }
                return "";
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .row-head {
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        display: inline;
        .splite{
            width: 10px;
            height: 15px;
            margin-top: 5px;
            float: left;
            background: #ff7700 !important;
        }
    }
    .overview-desc{
        font-size: 14px;
        text-align: left;
        padding-left: 30px;
    }
    .row_list{
        margin-bottom: 20px;
        .row_base{
            padding: 10px;
            box-sizing: border-box;
            background: #fff;
            border-radius: 6px;
            height: 120px;
        }
    }
    .order_list{
        .orderArea{
            width: 100%;
            height: 300px;
            background: #fff !important;
            border-radius: 6px;
            box-sizing: border-box;
            padding: 10px;
            padding-top: 40px;
            overflow: hidden;
        }
        .orderbarArea{
            height: 370px;
        }
    }
    .data_list{
        text-align: center;
        font-size: 14px;
        border-radius: 6px;
        padding: 10px;
        color: #393939;
        height: 80px;
        .leftItem{
            align-items: start;
            justify-content: space-between;
            text-align: left;
        }
        .rightItem{
            width:52px;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                font-size: 30px;
            }
        }
        .number{
            font-size: 22px;
            font-weight: bold;
            .perTitle{
                font-size: 13px;
                margin-left: 5px;
            }
        }
        .offline {
            color: red;
        }
    }
    .pay{
        .leftItem{
            justify-content: space-around;
        }
    }
    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }


</style>


