<template>
    <div class="examsAnalyseScoreLevelDialog">
        <el-dialog :title="myTitle" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">

            <el-row class="my-row">
                <el-col :span="4" >
                    优秀：
                </el-col>
                <el-col :span="6" >
                    <el-input-number size="mini" :min="0" v-model="scoreLevels.score90.from"></el-input-number>
                </el-col>
                <el-col :span="4" style="text-align: center">
                    到
                </el-col>
                <el-col :span="6" >
                    <el-input-number size="mini" :min="0" v-model="scoreLevels.score90.to"></el-input-number>
                </el-col>
            </el-row>

            <el-row class="my-row">
                <el-col :span="4" >
                    良好：
                </el-col>
                <el-col :span="6" >
                    <el-input-number size="mini" :min="0" v-model="scoreLevels.score80.from"></el-input-number>
                </el-col>
                <el-col :span="4" style="text-align: center">
                    到
                </el-col>
                <el-col :span="6" >
                    <el-input-number size="mini" :min="0" v-model="scoreLevels.score80.to"></el-input-number>
                </el-col>
            </el-row>

            <el-row class="my-row">
                <el-col :span="4" >
                    中等：
                </el-col>
                <el-col :span="6" >
                    <el-input-number size="mini" :min="0" v-model="scoreLevels.score70.from"></el-input-number>
                </el-col>
                <el-col :span="4" style="text-align: center">
                    到
                </el-col>
                <el-col :span="6" >
                    <el-input-number size="mini" :min="0" v-model="scoreLevels.score70.to"></el-input-number>
                </el-col>
            </el-row>

            <el-row class="my-row">
                <el-col :span="4" >
                    及格：
                </el-col>
                <el-col :span="6" >
                    <el-input-number size="mini" :min="0" v-model="scoreLevels.score60.from"></el-input-number>
                </el-col>
                <el-col :span="4" style="text-align: center">
                    到
                </el-col>
                <el-col :span="6" >
                    <el-input-number size="mini" :min="0" v-model="scoreLevels.score60.to"></el-input-number>
                </el-col>
            </el-row>

            <el-row class="my-row">
                <el-col :span="4" >
                    不及格：
                </el-col>
                <el-col :span="6" >
                    <el-input-number size="mini" :min="0" :disabled="true" v-model="scoreLevels.score0.from"></el-input-number>
                </el-col>
                <el-col :span="4" style="text-align: center">
                    到
                </el-col>
                <el-col :span="6" >
                    <el-input-number size="mini" :min="0" v-model="scoreLevels.score0.to"></el-input-number>
                </el-col>
            </el-row>
            
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'examsAnalyseScoreLevelDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '成绩分档设置',
                dialogVisible:false, loading: false, examId: 0,
                scoreLevels: {score90: {from: 90, to: 100}, score80: {from: 80, to: 90}, score70: {from: 70, to: 80},
                    score60: {from: 60, to: 70}, score0: {from: 0, to: 60},},

            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {

        },
        watch: {

        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            loadScoreRule(examId){
                this.examId = examId;
                let that = this;
                console.log("loadExamPages")
                let search = { licenceId: this.userInfo.licenceId, examId: examId};
                let param = { controllerName: 'score/ctrl', methodName: '/one',  param: search};
                return simpleApi.post(param).then(({result: {code, data}}) =>  {
                    console.log("loadScoreRule", data)
                    if (data) {
                        if(data.topic){
                            let topicList = data.topic;
                            let topicScore = 0;
                            for (let i=0; i<topicList.length; i++){
                                topicScore = topicScore + topicList[i].score;
                            }
                            this.scoreLevels.score90.to = topicScore;
                        }
                        if(data.entity && data.entity.scoreRule){
                            let scoreRule = data.entity.scoreRule;
                            try{
                                console.log("scoreRule", scoreRule)
                                let jo = JSON.parse(scoreRule);
                                console.log("pase", jo)
                                if("scoreRule" in jo){
                                    let sr = jo["scoreRule"];
                                    this.scoreLevels.score90.from = sr["score90"]["from"];
                                    this.scoreLevels.score90.to = sr["score90"]["to"];
                                    this.scoreLevels.score80.from = sr["score80"]["from"];
                                    this.scoreLevels.score80.to = sr["score80"]["to"];
                                    this.scoreLevels.score70.from = sr["score70"]["from"];
                                    this.scoreLevels.score70.to = sr["score70"]["to"];
                                    this.scoreLevels.score60.from = sr["score60"]["from"];
                                    this.scoreLevels.score60.to = sr["score60"]["to"];
                                    this.scoreLevels.score0.from = sr["score0"]["from"];
                                    this.scoreLevels.score0.to = sr["score0"]["to"];
                                }
                            }catch (e) {
                                console.log("loadScoreRule", e)
                            }
                        }

                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            submitForm(){
                let that = this;
                this.scoreLevels.score60.from = this.scoreLevels.score0.to;
                this.scoreLevels.score70.from = this.scoreLevels.score60.to;
                this.scoreLevels.score80.from = this.scoreLevels.score70.to;
                this.scoreLevels.score90.from = this.scoreLevels.score80.to;
                let my_rule =  JSON.stringify({ scoreRule: this.scoreLevels});

                let param = { controllerName: 'score/ctrl', methodName: '/updateScoreRule', param: {examId: this.examId, scoreRule: my_rule}};
                console.log("submit", param)
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('hardClass', code);
                    if (code == 0) {
                        that.dialogVisible = false;
                        that.$emit('ok', this.scoreLevels);
                    }else{
                        that.$message({message: '保存失败', type: 'error'});
                    }

                }).catch((error) => {

                });
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .examsAnalyseScoreLevelDialog{

        .my-row{
            padding: 10px;
        }

        .head-warning {
            margin-top: 20px;
            color: red;
        }
    }


</style>


